import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { HijiryDateService } from '../services/hijiry-date.service';

@Pipe({
  name: 'hijirydate',
})
export class HijirydatePipe implements PipeTransform {
  constructor(private hijriService: HijiryDateService) {}
  transform(value: string, ...args: unknown[]): unknown {
    const allowHijri = JSON.parse(localStorage.getItem('allowHijri'));
    if (value) {
      if (allowHijri) {
        return this.hijriService.convertGregorianToHijriWrite(
          new Date(
            Number(value.split('T')[0].split('-')[0]),
            Number(value.split('T')[0].split('-')[1]) - 1,
            Number(value.split('T')[0].split('-')[2]),
          ),
          0,
        );
      } else {
        return formatDate(value, 'mediumDate', 'en-US');
      }
    }
  }
}
