<form [formGroup]="currentGroup" *ngIf="!standAlone">
  <ng-container *ngIf="!type">
    <div
      class="file-upload"
      appDropZone
      (isHover)="isHover($event)"
      (dropped)="dropped($event)"
      (DropFileName)="setFileName($event)"
      (MultiFilesUrl)="setFileListUrl($event)"
      (MultiFilesArray)="setFileArray($event)"
      [multiFile]="multiFile"
      [ngClass]="{ hovering: isHovered }"
    >
      <h2 class="fileupload__heading">
        <!-- {{'DROPZONE.dropMessage' | translate}} -->
        <i class="fa-solid fa-cloud-arrow-up"></i>
      </h2>
      <input
        type="file"
        (change)="uploadFile($event)"
        [id]="label"
        [multiple]="multiFile"
        [accept]="accept"
        [formControlName]="formControlLabel"
      />
      <label class="file-label" [for]="label">
        <span>
          {{ 'DROPZONE.drop' | translate }}
        </span>
      </label>
      <h2 class="fileupload__heading2">{{ fileName }}</h2>

      <!-- <div     class="progressbar" *ngIf="percentage | async as pct">
			<div     [ngStyle]="{'width' : pct}" class="progressbar__mover"></div>
		</div> -->
      <!-- <div     class="alert alert-danger" role="alert">must upload file</div> -->
    </div>
    <div class="images-grid" *ngIf="fileListUrl.length > 0">
      <ng-container
        *ngFor="let img of fileListUrl; let i = index; trackBy: trackFn"
      >
        <div class="images-grid__item">
          <button class="images-grid__btn" (click)="removeFile(i)">
            <i class="fa-light fa-trash"></i>
          </button>
          <img [src]="img" alt="image" />
          <div class="images-grid__overlay"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="type === 'edit'">
    <div
      class="file-upload file-upload--no-border"
      (DropFileName)="setFileName($event)"
      [ngClass]="{ hovering: isHovered }"
    >
      <input
        type="file"
        [accept]="accceptObs$ | async"
        (change)="uploadFile($event)"
        id="file-upload"
      />

      <label class="file-label file-label--radius" for="file-upload">
        <img [src]="fileSrc ? fileSrc : imageUrl" alt="{{ fileName }}" />
        <span class="icon-upload-pen">
          <i class="fa-solid fa-pen"></i>
        </span>
      </label>
    </div>
  </ng-container>

  <ng-container *ngIf="type === 'attachment'">
    <div
      class="file-upload file-upload--no-border file-upload--attach attachment-actionBar"
      (DropFileName)="setFileName($event)"
      [ngClass]="{ hovering: isHovered }"
    >
      <input
        type="file"
        [accept]="accceptObs$ | async"
        (change)="uploadFile($event)"
        [id]="label ? label : 'file-upload'"
      />
      <label class="btn" [for]="label ? label : 'file-upload'">
        <!-- <img [src]="fileSrc ? fileSrc : imageUrl" alt="{{ fileName }}" /> -->
        <span class="icon-upload-pen">
          <i class="fa-light fa-file-arrow-up"></i>
        </span>
      </label>
    </div>
  </ng-container>

  <ng-container *ngIf="type === 'btn-upload'">
    <div
      class="file-upload file-upload--no-border file-upload--btn"
      (DropFileName)="setFileName($event)"
      [ngClass]="{ hovering: isHovered }"
    >
      <input
        type="file"
        [accept]="accceptObs$ | async"
        (change)="uploadFile($event)"
        [id]="label ? label : 'file-upload'"
      />
      <label class="btn" [for]="label ? label : 'file-upload'">
        <!-- <img [src]="fileSrc ? fileSrc : imageUrl" alt="{{ fileName }}" /> -->
        <span> إختر ملف </span>
      </label>
      <div class="file-upload--btn__filename">
        {{ fileName ? fileName : 'لا يوجد ملف' }}
      </div>
    </div>
  </ng-container>
</form>

<ng-container *ngIf="type === 'btn-upload' && standAlone">
  <div
    class="file-upload file-upload--no-border file-upload--btn"
    (DropFileName)="setFileName($event)"
    [ngClass]="{ hovering: isHovered }"
  >
    <input
      type="file"
      (change)="uploadFile($event)"
      [accept]="accceptObs$ | async"
      [id]="label ? label : 'file-upload'"
    />
    <label class="btn" [for]="label ? label : 'file-upload'">
      <!-- <img [src]="fileSrc ? fileSrc : imageUrl" alt="{{ fileName }}" /> -->
      <span> إختر ملف </span>
    </label>
    <div class="file-upload--btn__filename">
      {{ fileName ? fileName : 'لا يوجد ملف' }}
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === 'upload-files' && standAlone">
  <div
    class="file-upload file-upload--no-border file-upload--btn"
    (DropFileName)="setFileName($event)"
    [ngClass]="{ hovering: isHovered }"
  >
    <input
      type="file"
      [accept]="accept"
      (change)="uploadFileStand($event)"
      [id]="label ? label : 'file-upload'"
    />
    <label class="btn" [for]="label ? label : 'file-upload'">
      <!-- <img [src]="fileSrc ? fileSrc : imageUrl" alt="{{ fileName }}" /> -->
      <span> إختر ملف </span>
    </label>
    <div class="file-upload--btn__filename">
      {{ fileName ? fileName : 'لا يوجد ملف' }}
    </div>
  </div>
</ng-container>
