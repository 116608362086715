import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { HelperService } from 'src/app/core/helpers/helper.service';
import { ExDepartmentService } from 'src/app/core/services/ex-department.service';
import { LoukupsService } from 'src/app/core/services/loukups.service';
import { ManageDepartmentService } from 'src/app/core/services/manage-department.service';
import { ManageEmployeesService } from 'src/app/core/services/manage-employees.service';
import { SearchService } from 'src/app/core/services/search.service';
import { boxes } from 'src/app/core/staticData/box-types.data';
import { ReceiveMethod } from 'src/app/core/staticData/recieveMethod.data';
import { TransactionStateEnumData } from 'src/app/core/staticData/stateType';
import { TransactionType } from 'src/app/core/staticData/transactions.data';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
})
export class AdvancedSearchComponent implements OnInit, OnDestroy {
  exDepartments;
  _unsubscribeAll = new Subject();
  inbound: boolean = true;
  outbound: boolean = true;
  internal: boolean = true;

  TransactionType = TransactionType;
  ReceiveMethods;
  TransactionStates = TransactionStateEnumData;
  Confidentialitylevels: any[] = [];
  PeriorityLevels: any[] = [];
  transactionClassification: any[] = [];
  letterTypes: any[] = [];
  transactionActions: any[] = [];
  internalDepartments: any[] = [];
  deafultAction: any[] = [];
  internalEmployee: any[] = [];

  searchForm: FormGroup;

  boxTypes = boxes;

  constructor(
    private _ExDepartmentService: ExDepartmentService,
    private _helperService: HelperService,
    private _router: Router,
    private activeModal: NgbActiveModal,
    private _LoukupsService: LoukupsService,
    private _DepartmentService: ManageDepartmentService,
    private _employeeService: ManageEmployeesService,
    private _searchService: SearchService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getExternalDepartment();
    this.getConfidentialitylevel();
    this.getLetterTypes();
    this.getPeriorityLevels();
    this.getReceivedMethod();
    this.getTransactionActions();
    this.getTransactionClassification();
    this.getFlatDepartment();
    this.getAllUserDepartment();

    this._searchService.searchParams
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((params) => {
        if (params) {
          // this.searchForm.patchValue(params);
          this.hasDate = params['hasDate'] ? params['hasDate'] : false;
          this.receivedByEmployee = params['receivedByEmployee']
            ? params['receivedByEmployee']
            : false;
        }
      });
  }

  routeChange($event) {}

  getAllUserDepartment() {
    this._employeeService
      .getAllUsersDepartment()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: any) => {
          this.internalEmployee = res?.results;
        },
        (error) => {
          this._helperService.handleError(error);
        },
      );
  }

  getFlatDepartment() {
    this._DepartmentService
      .getFlatDeprtments()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: any) => {
          this.internalDepartments = res?.results;
        },
        (error) => {
          this._helperService.handleError(error);
        },
      );
  }

  getExternalDepartment() {
    this._ExDepartmentService
      .getNewExterlnalDepartments()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: any) => {
          this.exDepartments = res?.results;
        },
        (error) => {
          this._helperService.handleError(error);
        },
      );
  }

  exDepSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    if (term.match(/^\d+$/)) {
      return item.entityNumber === term;
    } else {
      return (
        item?.nameAr?.toLocaleLowerCase().indexOf(term) > -1 ||
        item?.name?.toLocaleLowerCase().indexOf(term) > -1
      );
    }
  }

  getMultiple($event) {}

  clearMultiple() {}

  initForm() {
    this.searchForm = new FormGroup({
      subject: new FormControl('', []),
      // box : new FormControl(null,[]),
      transactionNumber: new FormControl(null, []),
      receiveMethod: new FormControl(null, []),
      destinationType: new FormControl(null, []),
      state: new FormControl(null, []),
      action: new FormControl('', []),
      forwordReason: new FormControl('', []),
      externalDepartment: new FormControl(null, []),
      creatorDepartment: new FormControl(null, []),
      transactionTypeId: new FormControl(null, []),
      confidentialityLevelId: new FormControl(null, []),
      letterTypeId: new FormControl(null, []),
      priorityLevelId: new FormControl(null, []),
      transactionClassificationId: new FormControl(null, []),
      internalDepartment: new FormControl(null, []),
      creatorName: new FormControl('', []),
      incommingNO: new FormControl('', []),
    });
  }

  hasDate: boolean = false;
  receivedByEmployee: boolean = false;

  selectHasDate($event: any) {
    this.hasDate = $event?.target?.checked;
  }

  isRecieved($event) {
    this.receivedByEmployee = $event?.target?.checked;
  }

  changeTransactionType(transaction) {
    const typeTransaction = transaction?.id;
    if (typeTransaction === 1) {
      this.inbound = true;
      this.outbound = true;
      this.internal = true;
    }
    if (typeTransaction === 2) {
      this.inbound = false;
      this.outbound = false;
      this.internal = true;
    }
    if (typeTransaction === 3) {
      this.inbound = false;
      this.outbound = true;
      this.internal = false;
    }
  }

  search() {
    this.activeModal.dismiss();
    const search = {
      ...this.searchForm.value,
    };

    search['hasDate'] = this.hasDate;
    search['receivedByEmployee'] = this.receivedByEmployee;
    this._router.navigate(['search'], {
      state: { location: 'search' },
      replaceUrl: true,
    });
    this._searchService.FromTopbar.next(false);
    this._searchService.searchParams.next(search);
    this._searchService.FullSearch.next(false);
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  getLetterTypes() {
    this._LoukupsService
      .getLetterTypes()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: any) => {
          this.letterTypes = res?.results;
          // this.entityForm.patchValue({letterTypeId:res?.results[0]?.id})
        },
        (error) => {
          this._helperService.handleError(error);
        },
      );
  }

  getTransactionClassification() {
    this._LoukupsService
      .getTransactionClassifications()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: any) => {
          this.transactionClassification = res?.results;
          // this.entityForm.patchValue({transactionClassificationId:res?.results[0]?.id})
        },
        (error) => {
          this._helperService.handleError(error);
        },
      );
  }

  getConfidentialitylevel() {
    this._LoukupsService
      .getConfidentialitylevel()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: any) => {
          this.Confidentialitylevels = res?.results;
          // this.entityForm.patchValue({confidentialityLevelId:res?.results[2]?.id})
        },
        (error) => {
          this._helperService.handleError(error);
        },
      );
  }

  getPeriorityLevels() {
    this._LoukupsService
      .getPeriorityLevels()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: any) => {
          this.PeriorityLevels = res?.results;
          // this.entityForm.patchValue({priorityLevelId:res?.results[0]?.id})
        },
        (error) => {
          this._helperService.handleError(error);
        },
      );
  }

  getReceivedMethod() {
    this._LoukupsService
      .getReceivedMethod()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: any) => {
          this.ReceiveMethods = res?.results;
        },
        (error) => {
          this._helperService.handleError(error);
        },
      );
  }

  getTransactionActions() {
    this._LoukupsService
      .getTransactionActions()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res: any) => {
          this.deafultAction = res?.results[0];
          this.transactionActions = res?.results;
          // this.entityForm.patchValue({ action: res?.results[0] });
          // this.entityForm.patchValue({ actionHidden: res?.results[0] });
        },
        (error) => {
          this._helperService.handleError(error);
        },
      );
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this._unsubscribeAll.next('');
      this._unsubscribeAll.complete();
    }, 0);
  }
}
