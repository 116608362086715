<div class="certification-container">
  <div class="p-2">
    <div class="titles">
      <!-- Basic Info -->
      <div class="d-flex justify-content-between align-items-center">
        <button
          type="submit"
          class="btn d-block px-0"
          (click)="backButton(transaction?.transactionId)"
        >
          <i class="fa-regular fa-arrow-left"></i>
          عودة
        </button>

        <button
          class="btn"
          type="button"
          ngbTooltip="طباعة"
          (click)="displayPrintDetails = true; newPrint()"
          placement="right"
        >
          <i class="fa-solid fa-print text-primary"></i>
        </button>
      </div>

      <div class="d-flex justify-content-between">
        <div class="py-1">
          <span class="title">تفاصيل المعاملة</span>
          <span class="title-name">
            <i class="fa-solid fa-square-poll-horizontal"></i> معاملة
            {{
              transaction?.transactionTypeId === 3
                ? ' صادر ' + transaction?.transactionType?.descriptionAr
                : transaction?.transactionTypeId === 2 &&
                  transaction?.state.state === 0
                ? 'مسودة صادر'
                : transaction?.transactionType?.descriptionAr
            }}
          </span>
        </div>
      </div>
      <!-- Basic Info -->
      <div class="py-1">
        <span class="title"
          >رقم المعاملة: {{ transaction?.transactionNumber }}</span
        >
        <span class="title-name">
          <i class="fa-regular fa-calendar"></i>
          {{ transaction?.creationDate | hijirydate }}
        </span>
      </div>
      <div class="row py-1">
        <div class="col-md-3 title-name user-info-block">
          <div class="d-flex align-items-start">
            <div class="d-flex align-items-center">
              <i class="fa-regular fa-user"></i>
            </div>
            <div class="user-info-block__dep">
              <span> انشئت من قبل </span>
              <span>{{ transaction?.creatorNameAr }}</span>
              <span>{{ transaction?.creatorDepartmentName }}</span>
            </div>
          </div>
        </div>

        <div
          class="col-md-3 title-name user-info-block"
          *ngIf="
            transaction?.transactionTypeId === 3 ||
            transaction?.transactionTypeId === 1
          "
        >
          <div class="d-flex align-items-start">
            <div class="d-flex align-items-center">
              <i class="fa-light fa-paper-plane-top"></i>
            </div>
            <div class="user-info-block__dep">
              <span> أرسلت إلى </span>
              <span *ngIf="transaction?.state?.state !== 0">{{
                transaction?.to[0]?.nameAr
              }}</span>
              <span *ngIf="transaction?.state?.state !== 0">{{
                getDepartment(transaction?.to[0]?.departmentId)
                  ?.departmentNameAr
              }}</span>
              <span *ngIf="transaction?.state?.state === 0"
                >لم يتم إرساله بعد</span
              >
            </div>

            <!-- <div     class="user-info-block__dep" *ngIf="transaction?.transactionTypeId === 2">
              <span>
                صادر إلى
              </span>
              <span>{{transaction?.externalDepartment.descriptionAr}}</span>
            </div> -->
          </div>
        </div>

        <div
          class="col-md-3 title-name user-info-block"
          *ngIf="transaction?.transactionTypeId === 2"
        >
          <div class="d-flex align-items-start">
            <div class="d-flex align-items-center">
              <i class="fa-light fa-paper-plane-top"></i>
            </div>
          </div>

          <div class="user-info-block__dep">
            <span> صادرة إلى </span>
            <span> {{ transaction?.externalDepartment.descriptionAr }} </span>
          </div>
        </div>

        <div class="col-md-3 title-name user-info-block">
          <div class="d-flex align-items-start">
            <div class="d-flex align-items-center">
              <i class="fa-regular fa-calendar"></i>
            </div>
            <div class="user-info-block__dep">
              <span> تاريخ الإرسال </span>
              <span>{{
                transaction?.sendDate
                  ? (transaction?.sendDate | hijirydate)
                  : 'لم يتم إرساله بعد'
              }}</span>
            </div>
          </div>
        </div>

        <div class="col-md-3 title-name user-info-block">
          <div class="d-flex align-items-start">
            <div class="d-flex align-items-center">
              <i class="fa-solid fa-spinner"></i>
            </div>
            <div class="user-info-block__dep">
              <span> مكان المعاملة حاليًا: </span>
              <!-- <span> {{currentUser}} </span>
                           <span>{{currentLocation}}</span> -->

              <ng-container
                *ngIf="
                  boxType === 'inbox' ||
                  'sent' ||
                  'copies' ||
                  'secretary-box || managerBox'
                "
              >
                <span>{{ transaction?.to[0]?.nameAr }}</span>
                <span>{{
                  getDepartment(transaction?.to[0]?.departmentId)
                    ?.departmentNameAr
                }}</span>
              </ng-container>

              <ng-container *ngIf="boxType === 'draft-box'">
                <span *ngIf="transaction?.transactionTypeId === 2">{{
                  transaction?.to[0]?.nameAr
                }}</span>
                <span *ngIf="transaction?.transactionTypeId === 2">{{
                  getDepartment(transaction?.to[0]?.departmentId)
                    ?.departmentNameAr
                }}</span>

                <span *ngIf="transaction?.transactionTypeId !== 2">{{
                  transaction?.creatorNameAr
                }}</span>
                <span *ngIf="transaction?.transactionTypeId !== 2">{{
                  transaction?.creatorDepartmentName
                }}</span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-2">
    <ul
      (navChange)="navChange($event)"
      ngbNav
      [destroyOnHide]="false"
      [(activeId)]="active"
      #nav="ngbNav"
      class="nav-tabs justify-content-between text-center"
    >
      <!-- Basic Info -->
      <li [ngbNavItem]="1">
        <a ngbNavLink>
          <div class="tab-icon">
            <i class="fa-light fa-circle-info"></i>
          </div>
          <div class="tab-name">
            المعلومات <br />
            الأساسية
          </div>
        </a>
        <ng-template ngbNavContent>
          <div class="content mt-3">
            <div class="brdr">
              <p
                class="transaction-title"
                *ngIf="transaction?.transactionSubject"
              >
                موضوع المعاملة
              </p>
              <!-- <p class="title" > الموضوع</p> -->
              <p class="text-left">
                {{ transaction?.transactionSubject }}
              </p>
            </div>

            <div class="row mt-3">
              <!-- <div     class="col-md-3">
                              <div     class="brdr">
                                  <label class="title">{{transaction?.transactionTypeId === 1 ? "جهة الوارد" : "جهة الارسال"}}</label>
                                  <p *ngIf="transaction?.transactionTypeId === 1">{{transaction?.from[0].nameAr }}</p>
                                  <p *ngIf="transaction?.transactionTypeId === 3"> {{transaction?.to[0]?.nameAr}} , {{getDepartment(transaction?.to[0]?.departmentId)?.departmentNameAr}}</p>
                              </div>
                          </div> -->

              <div class="col-md-3">
                <div class="brdr">
                  <label class="title">{{
                    transaction?.transactionTypeId === 1
                      ? 'جهة الوارد'
                      : transaction?.transactionTypeId === 2
                      ? 'جهة الصادر'
                      : 'جهة الإرسال'
                  }}</label>
                  <p *ngIf="transaction?.transactionTypeId === 1">
                    {{ transaction?.from[0].nameAr }}
                  </p>
                  <p *ngIf="transaction?.transactionTypeId === 2">
                    {{ transaction?.externalDepartment.descriptionAr }}
                  </p>
                  <p *ngIf="transaction?.transactionTypeId === 3">
                    {{ transaction?.to[0]?.nameAr }} ,
                    {{
                      getDepartment(transaction?.to[0]?.departmentId)
                        ?.departmentNameAr
                    }}
                  </p>
                </div>
              </div>

              <div class="col-md-3">
                <div class="brdr">
                  <label class="title">نوع الأولوية</label>
                  <p>{{ transaction?.priorityLevel?.descriptionAr }}</p>
                </div>
              </div>

              <div class="col-md-3">
                <div class="brdr">
                  <label class="title">نوع السرية</label>
                  <p>{{ transaction?.confidentialityLevel?.descriptionAr }}</p>
                </div>
              </div>

              <div class="col-md-3">
                <div class="brdr">
                  <label class="title">نوع خطاب المعاملة</label>
                  <p>{{ transaction?.letterType?.descriptionAr }}</p>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-3">
                <div class="brdr">
                  <label class="title">طريقة التسليم</label>
                  <p>{{ transaction?.receive_Method?.descriptionAr }}</p>
                </div>
              </div>

              <div class="col-md-3">
                <div class="brdr">
                  <label class="title">تصنيف المعاملة</label>
                  <p>
                    {{
                      transaction?.transactionClassification
                        ? transaction?.transactionClassification?.descriptionAr
                        : 'لا يوجد'
                    }}
                  </p>
                </div>
              </div>

              <div class="col-md-3">
                <div class="brdr">
                  <label class="title">ملف الأرشيف </label>
                  <p>
                    {{
                      transaction?.archiveFileText
                        ? transaction?.archiveFileText
                        : 'لا يوجد'
                    }}
                  </p>
                </div>
              </div>
            </div>

            <div class="brdr mt-3">
              <p class="transaction-title">ملاحظات المعاملة</p>
              <p class="">
                {{ transaction?.notes ? transaction?.notes : 'لا يوجد' }}
              </p>
            </div>

            <div class="mt-3">
              <p class="transaction-title">المواعيد</p>

              <div class="row">
                <div class="col-md-3">
                  <div class="brdr">
                    <label class="title">موعد محدد</label>
                    <p>
                      {{ transaction?.appointmentDate ? 'مع موعد' : 'لا يوجد' }}
                    </p>
                  </div>
                </div>

                <div class="col-md-3 brdr">
                  <label class="title">بتاريخ</label>
                  <div class="d-flex justify-content-around">
                    <ng-container
                      *ngIf="transaction?.appointmentDate; else noDate"
                    >
                      <span>{{
                        transaction?.appointmentDate.split('T')[0].split('-')[2]
                      }}</span>
                      <span>{{
                        transaction?.appointmentDate.split('T')[0].split('-')[1]
                      }}</span>
                      <span>{{
                        transaction?.appointmentDate.split('T')[0].split('-')[0]
                      }}</span>
                    </ng-container>
                    <ng-template #noDate>
                      <span>لا يوجد</span>
                    </ng-template>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="brdr">
                    <label class="title">حالة المعاملة</label>
                    <p>{{ transaction?.state?.nameAr }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-3">
              <p class="transaction-title">
                النسخ المرسلة <i class="fa-solid fa-angle-up mx-1"></i>
              </p>

              <div
                *ngIf="permissionStrings.Internal_ECopies_Record | showElement"
              >
                <p class="title">النسخ العادية</p>

                <div
                  class="row"
                  *ngIf="transaction?.copies.length > 0; else noCopies"
                >
                  <table class="table text-center" id="copies">
                    <thead class="title">
                      <tr>
                        <th>اسم الإدارة</th>
                        <th>اسم الموظف</th>
                        <th>الإجراء المطلوب</th>
                        <th>مرسل مع المرفقات</th>
                        <th>تم الاطلاع</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let copy of transaction?.copies">
                        <tr>
                          <td *ngIf="copy?.type === 1">
                            {{
                              getDepartment(copy?.departmentId)
                                ?.departmentNameAr
                            }}
                          </td>
                          <td *ngIf="copy?.type === 2">{{ copy?.nameAr }}</td>
                          <td>{{ copy?.nameAr }}</td>
                          <td>
                            {{ copy?.actionAr ? copy?.actionAr : 'لا يوجد' }}
                          </td>
                          <td>
                            <button class="btn">
                              <i
                                *ngIf="!copy?.includeAttatchments"
                                class="fa-solid fa-xmark"
                              ></i>
                              <i
                                *ngIf="copy?.includeAttatchments"
                                class="fa-solid fa-check"
                              ></i>
                            </button>
                          </td>
                          <td *ngIf="copy?.unSeen">
                            <i
                              class="fa-solid fa-check-double text-info seen-icon"
                            ></i>
                          </td>
                          <td *ngIf="!copy?.unSeen">
                            <i class="fa-solid fa-check-double seen-icon"></i>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>

                  <!--End col-->
                </div>

                <ng-template #noCopies>
                  <p class="title">لا يوجد نسخ</p>
                </ng-template>
              </div>

              <div
                *ngIf="
                  permissionStrings.Transaction_View_BCC_IN_Certificate
                    | showElement
                "
              >
                <p class="title">النسخ المخفية</p>

                <div
                  class="mt-3"
                  *ngIf="transaction?.hiddenCopies.length > 0; else noHidden"
                >
                  <div class="row">
                    <table class="table text-center" id="hiddenCopies">
                      <thead class="title">
                        <tr>
                          <th>اسم الإدارة</th>
                          <th>اسم الموظف</th>
                          <th>الإجراء المطلوب</th>
                          <th>مرسل مع المرفقات</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container
                          *ngFor="let copy of transaction?.hiddenCopies"
                        >
                          <tr>
                            <td *ngIf="copy?.type === 1">
                              {{
                                getDepartment(copy?.departmentId)
                                  ?.departmentNameAr
                              }}
                            </td>
                            <td *ngIf="copy?.type === 2">{{ copy?.nameAr }}</td>
                            <td>{{ copy?.nameAr }}</td>
                            <td>
                              {{ copy?.actionAr ? copy?.actionAr : 'لا يوجد' }}
                            </td>
                            <td>
                              <button class="btn">
                                <i
                                  *ngIf="!copy?.includeAttatchments"
                                  class="fa-solid fa-xmark"
                                ></i>
                                <i
                                  *ngIf="copy?.includeAttatchments"
                                  class="fa-solid fa-check"
                                ></i>
                              </button>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                    <!--End col-->
                  </div>
                </div>

                <ng-template #noHidden>
                  <p class="title">لا يوجد نسخ مخفية</p>
                </ng-template>
              </div>

              <div
                *ngIf="permissionStrings.External_ECopies_Record | showElement"
              >
                <p class="title">النسخ الخارجية</p>

                <div
                  class="mt-3"
                  *ngIf="
                    transaction?.externalDepartmentCopies.length > 0;
                    else noOutbound
                  "
                >
                  <div class="row">
                    <table class="table text-center" id="externalCopies">
                      <thead class="title">
                        <tr>
                          <th>اسم الجهة</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container
                          *ngFor="
                            let copy of transaction?.externalDepartmentCopies
                          "
                        >
                          <tr>
                            <td>
                              {{ copy?.nameAr }}
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                    <!--End col-->
                  </div>
                </div>

                <ng-template #noOutbound>
                  <p class="title">لا يوجد نسخ خارجية</p>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <!-- Basic Info -->

      <!-- archives -->
      <li [ngbNavItem]="2">
        <a ngbNavLink>
          <div class="tab-icon">
            <i class="fa-light fa-box-archive"></i>
          </div>
          <div class="tab-name">الأرشفة</div>
        </a>
        <ng-template ngbNavContent>
          <!--card-->
          <div class="card card--whitebg">
            <ul
              ngbNav
              #customNav="ngbNav"
              [destroyOnHide]="false"
              [activeId]="1"
              class="nav-tabs nav-tabs-custom mb-3"
            >
              <li [ngbNavItem]="1" (click)="setActive = 1">
                <a ngbNavLink>
                  <span class="d-none d-sm-block">خطاب أصل المعاملة</span>
                </a>
                <ng-template ngbNavContent>
                  <div class="card card--gray">
                    <div class="card card--whitebg">
                      <div class="content-wrraper">
                        <ejs-pdfviewer
                          #pdfViewer
                          id="mainFile"
                          [serviceUrl]="SyncService"
                          [documentPath]="document"
                          [enableRtl]="true"
                          locale="ar-AE"
                          style="height: 640px; display: block"
                        >
                        </ejs-pdfviewer>
                      </div>
                    </div>
                    <!-- <div     class="card card--whitebg" #pdfViewer2>

                                  </div> -->
                  </div>
                </ng-template>
              </li>

              <li [ngbNavItem]="2" (click)="setActive = 2">
                <a ngbNavLink>
                  <span class="d-none d-sm-block">مرفقات آخرى</span>
                </a>
                <ng-template ngbNavContent>
                  <div class="row p-2">
                    <ng-container
                      *ngIf="
                        permissionStrings.View_Transation_Letter_Attachements_Record
                          | showElement;
                        else noAccess
                      "
                    >
                      <div>
                        <ng-container
                          *ngIf="multipleAttachArray.length > 0; else noAttach"
                        >
                          <div class="table-responsive">
                            <table class="table align-middle table-nowrap">
                              <thead>
                                <tr>
                                  <th>نوع المرفق</th>
                                  <th>عدد الصفحات</th>
                                  <th>الملاحظات</th>
                                  <th>العمليات</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container
                                  *ngFor="
                                    let atttach of multipleAttachArray;
                                    let i = index;
                                    trackBy: trackAtt
                                  "
                                >
                                  <tr>
                                    <td>
                                      {{
                                        atttach?.attachmentType?.descriptionAr
                                      }}
                                    </td>

                                    <td>
                                      {{
                                        atttach?.noPages
                                          ? atttach?.noPages
                                          : 'لا يوجد'
                                      }}
                                    </td>

                                    <td>
                                      {{
                                        atttach?.notes
                                          ? atttach?.notes
                                          : 'لا يوجد ملاحظات'
                                      }}
                                    </td>
                                    <td>
                                      <i
                                        (click)="viewAttachFromArray(i)"
                                        class="fa-light fa-eye"
                                      ></i>
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>

                          <div class="card card--gray">
                            <div class="card card--whitebg">
                              <div class="content-wrraper">
                                <ejs-pdfviewer
                                  #attachViewer
                                  id="attachViewer"
                                  [serviceUrl]="SyncService"
                                  [documentPath]="document"
                                  [enableRtl]="true"
                                  locale="ar-AE"
                                  style="height: 640px; display: block"
                                >
                                </ejs-pdfviewer>
                              </div>
                            </div>
                            <!-- <div     class="card card--whitebg" #pdfViewer2>

                                        </div> -->
                          </div>
                        </ng-container>

                        <ng-template #noAttach>
                          <app-no-data
                            [message]="'لا يوجد مرفقات'"
                            [icon]="'fa-light fa-link'"
                          >
                          </app-no-data>
                        </ng-template>
                      </div>
                    </ng-container>

                    <ng-template #noAccess>
                      <app-no-data
                        [message]="'لا تمتلك الصلاحية'"
                        [icon]="'fa-light fa-binoculars'"
                      >
                      </app-no-data>
                    </ng-template>
                    <!--End col-->
                  </div>
                </ng-template>
              </li>
            </ul>
            <div class="d-flex button-group attachment-actionBar">
              <button
                type="submit"
                class="btn me-2"
                ngbTooltip="طباعة"
                placement="top"
                *ngIf="permissionStrings.TransactionPrint | showElement"
                (click)="printAttachFile()"
              >
                <i class="fa-light fa-print"></i>
              </button>
            </div>
            <div [ngbNavOutlet]="customNav"></div>
          </div>
          <!--card-->
        </ng-template>
      </li>
      <!-- archives -->

      <!-- explains -->
      <li [ngbNavItem]="3">
        <a ngbNavLink>
          <div class="tab-icon">
            <i class="fa-light fa-bars-progress"></i>
          </div>
          <div class="tab-name">الشروحات</div>
        </a>
        <ng-template ngbNavContent>
          <div class="row p-5">
            <ng-container
              *ngIf="
                permissionStrings.Transaction_Explanations_log | showElement;
                else noAccess
              "
            >
              <div class="content">
                <div class="d-flex justify-content-between">
                  <p class="transaction-title mt-2 p-2">شروحات الإحالات</p>
                  <button
                    class="btn"
                    type="button"
                    ngbTooltip="طباعة"
                    *ngIf="Referrals?.results?.length > 0"
                    (click)="printForward()"
                    placement="right"
                  >
                    <i class="fa-solid fa-print text-primary"></i>
                  </button>
                </div>
                <ng-container
                  *ngIf="Referrals?.results?.length > 0; else noData"
                >
                  <table class="table text-center" id="referralTable">
                    <thead class="title">
                      <tr>
                        <th>الموظف</th>
                        <th>الإدارة</th>
                        <th>الشرح</th>
                        <th>التاريخ و الوقت</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let referral of Referrals?.results">
                        <tr>
                          <td>{{ referral?.sentFromUserFullNameAr }}</td>
                          <td>
                            {{
                              referral?.sentFromDepartmentAr
                                ? referral?.sentFromDepartmentAr
                                : 'لا يوجد'
                            }}
                          </td>
                          <td>{{ referral?.forwordReason }}</td>

                          <td>
                            {{ referral?.referralDate | hijrydatetime }}
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </ng-container>

                <ng-template #noData>
                  <app-no-data
                    [message]="'لا يوجد شروحات'"
                    [icon]="'fa-light fa-binoculars'"
                  >
                  </app-no-data>
                </ng-template>

                <div class="p-2" *ngIf="Referrals?.results?.length > 0">
                  <ngb-pagination
                    aria-label="Custom pagination"
                    [collectionSize]="collectionSize"
                    [(page)]="currentPage"
                    (pageChange)="changePage($event, 'Referrals')"
                    [pageSize]="pageSize"
                    [maxSize]="3"
                    [rotate]="true"
                    class="pagination pagination-rounded"
                  >
                    <ng-template ngbPaginationPrevious>
                      <i class="fa-light fa-chevron-left"></i>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                      <i class="fa-light fa-chevron-right"></i>
                    </ng-template>
                  </ngb-pagination>
                </div>
              </div>
            </ng-container>

            <ng-template #noAccess>
              <app-no-data
                [message]="'لا تمتلك الصلاحية'"
                [icon]="'fa-light fa-binoculars'"
              >
              </app-no-data>
            </ng-template>
            <!--End col-->
          </div>
        </ng-template>
      </li>
      <!-- explains -->

      <!-- referrals -->
      <li [ngbNavItem]="4">
        <a ngbNavLink>
          <div class="tab-icon">
            <i class="fa-light fa-reply"></i>
          </div>
          <div class="tab-name">الاحالات</div>
        </a>
        <ng-template ngbNavContent>
          <div class="row p-5">
            <ng-container
              *ngIf="
                permissionStrings.Transaction_Referrals | showElement;
                else noAccess
              "
            >
              <div class="content">
                <ng-container
                  *ngIf="Referrals?.results?.length > 0; else noData"
                >
                  <table class="table text-center" id="referrals">
                    <thead class="title">
                      <tr>
                        <th>الموظف</th>
                        <th>الإدارة</th>
                        <th>وقت وتاريخ الارسال</th>
                        <th>الاجراء</th>
                        <th>الموظف</th>
                        <th>الإدارة</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let referral of Referrals?.results">
                        <tr>
                          <td>{{ referral?.sentFromUserFullNameAr }}</td>
                          <td>
                            {{
                              referral?.sentFromDepartmentAr
                                ? referral?.sentFromDepartmentAr
                                : 'لا يوجد'
                            }}
                          </td>
                          <td>
                            {{ referral?.referralDate | hijrydatetime }}
                          </td>
                          <td>
                            {{
                              referral?.actionAr
                                ? referral?.actionAr
                                : 'لا يوجد'
                            }}
                          </td>
                          <td>{{ referral?.sendToUserFullNameAr }}</td>
                          <td>{{ referral?.sendToDepartmentAr }}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </ng-container>

                <ng-template #noData>
                  <app-no-data
                    [message]="'لا يوجد إحالات'"
                    [icon]="'fa-light fa-binoculars'"
                  >
                  </app-no-data>
                </ng-template>
              </div>
            </ng-container>

            <ng-template #noAccess>
              <app-no-data
                [message]="'لا تمتلك الصلاحية'"
                [icon]="'fa-light fa-binoculars'"
              >
              </app-no-data>
            </ng-template>

            <div class="p-2" *ngIf="Referrals?.results?.length > 0">
              <ngb-pagination
                aria-label="Custom pagination"
                [collectionSize]="collectionSize"
                [(page)]="currentPage"
                (pageChange)="changePage($event, 'Referrals')"
                [pageSize]="pageSize"
                [maxSize]="3"
                [rotate]="true"
                class="pagination pagination-rounded"
              >
                <ng-template ngbPaginationPrevious>
                  <i class="fa-light fa-chevron-left"></i>
                </ng-template>
                <ng-template ngbPaginationNext>
                  <i class="fa-light fa-chevron-right"></i>
                </ng-template>
              </ngb-pagination>
            </div>
            <!--End col-->
          </div>
        </ng-template>
      </li>
      <!-- referrals -->

      <!-- links -->
      <li [ngbNavItem]="5">
        <a ngbNavLink>
          <div class="tab-icon">
            <i class="fa-light fa-link"></i>
          </div>
          <div class="tab-name">الربط</div>
        </a>
        <ng-template ngbNavContent>
          <div class="row p-5">
            <ng-container
              *ngIf="
                permissionStrings.Linked_Transactions_Record | showElement;
                else noAccess
              "
            >
              <div class="content">
                <p class="transaction-title">بيانات الربط</p>

                <div class="row">
                  <table
                    class="table text-center"
                    *ngIf="
                      transaction?.linkedTransactions?.length > 0;
                      else noData
                    "
                  >
                    <thead class="title">
                      <tr>
                        <th>رقم المعاملة</th>
                        <th>جهة المعاملة</th>
                        <th>تاريخ الإنشاء</th>
                        <th>موضوع المعاملة</th>
                        <th>نوع المعاملة</th>
                      </tr>
                    </thead>
                    <tbody class="link">
                      <ng-container
                        *ngFor="let link of transaction?.linkedTransactions"
                      >
                        <tr
                          (click)="
                            goToLinked(
                              link?.transactionId,
                              transaction.transactionId
                            )
                          "
                        >
                          <td>{{ link?.transactionNumber }}</td>
                          <td>{{ link?.externalDepartmentAr }}</td>
                          <td>{{ link?.creationYear }}</td>
                          <td>{{ link?.transactionSubject }}</td>
                          <td>{{ link?.transactionType?.descriptionAr }}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>

                  <ng-template #noData>
                    <app-no-data
                      [message]="'لا يوجد معاملات'"
                      [icon]="'fa-thin fa-file'"
                    ></app-no-data>
                  </ng-template>
                </div>
              </div>
            </ng-container>

            <ng-template #noAccess>
              <app-no-data
                [message]="'لا تمتلك الصلاحية'"
                [icon]="'fa-light fa-binoculars'"
              >
              </app-no-data>
            </ng-template>
            <!--End col-->
          </div>
        </ng-template>
      </li>
      <!-- links -->

      <!-- reference-data -->

      <li [ngbNavItem]="6">
        <a ngbNavLink>
          <div class="tab-icon">
            <i class="fa-light fa-address-card"></i>
          </div>
          <div class="tab-name">بيانات المراجع</div>
        </a>
        <ng-template ngbNavContent>
          <div class="row p-5">
            <ng-container
              *ngIf="
                permissionStrings.View_Stakeholders_Information_Record
                  | showElement;
                else noAccess
              "
            >
              <div class="content reference-data">
                <p class="transaction-title">بيانات المراجعين</p>

                <div
                  class="row"
                  *ngIf="transaction?.reference?.length > 0; else noRef"
                >
                  <table class="table text-center table-hover">
                    <thead class="title">
                      <tr data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <th>الاسم كامل</th>
                        <th>رقم الهوية</th>
                        <th>الجنسية</th>
                        <th>رقم الجوال</th>
                        <th>الجنس</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let ref of transaction?.reference">
                        <tr (click)="openModal(content); selectRef(ref)">
                          <td>{{ ref?.fullName }}</td>
                          <td>
                            {{
                              ref?.idNumber === '' ? 'لا يوجد' : ref?.idNumber
                            }}
                          </td>
                          <td>
                            {{
                              ref?.country === 'non Saudian'
                                ? 'غير سعودي'
                                : 'سعودي'
                            }}
                          </td>
                          <td>{{ ref?.mobile }}</td>
                          <td>{{ ref?.female === 0 ? 'ذكر' : 'أنثي' }}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>

                <ng-template #noRef>
                  <app-no-data
                    [message]="'لا يوجد مراجعين'"
                    [icon]="'fa-light fa-users'"
                  ></app-no-data>
                </ng-template>
              </div>
            </ng-container>

            <ng-template #noAccess>
              <app-no-data
                [message]="'لا تمتلك الصلاحية'"
                [icon]="'fa-light fa-binoculars'"
              >
              </app-no-data>
            </ng-template>

            <!--End col-->
          </div>

          <!-- modal -->
          <ng-template #content role="document" let-modal>
            <div class="modal-header">
              <h5 class="modal-title mt-0">تفاصيل البيانات الشخصية</h5>
              <button
                type="button"
                class="btn-close"
                aria-hidden="true"
                (click)="modal.dismiss('Cross click')"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row content">
                <div class="col-md-6">
                  <div>
                    <label class="title">الاسم الكامل</label>
                    <p>{{ currentRef?.fullName }}</p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div>
                    <label class="title">الجنسية</label>
                    <p>
                      {{
                        currentRef?.country === 'non Saudian'
                          ? 'غير سعودي'
                          : 'سعودي'
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="row content">
                <div class="col-md-6">
                  <div>
                    <label class="title">الجنس</label>
                    <p>{{ currentRef?.female === 0 ? 'ذكر' : 'أنثي' }}</p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div>
                    <label class="title">رقم الهوية</label>
                    <p>{{ currentRef?.idNumber }}</p>
                  </div>
                </div>
              </div>

              <div class="row content">
                <div class="col-md-6">
                  <div>
                    <label class="title">رقم الجوال</label>
                    <p>{{ currentRef?.mobile }}</p>
                  </div>
                </div>

                <div class="col-md-6">
                  <div>
                    <label class="title">العنوان</label>
                    <p>{{ currentRef?.address }}</p>
                  </div>
                </div>
              </div>

              <div class="row content">
                <div class="col-md-6">
                  <div>
                    <label class="title">البريد الإلكتروني</label>
                    <p>{{ currentRef?.email }}</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <label class="title">الملاحظات</label>
                    <p>
                      {{ currentRef?.notes ? currentRef?.notes : 'لا يوجد' }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <!-- modal -->
        </ng-template>
      </li>
      <!-- reference-data -->

      <!-- Followup -->
      <li [ngbNavItem]="7">
        <a ngbNavLink>
          <div class="tab-icon">
            <i class="fa-thin fa-address-card"></i>
          </div>
          <div class="tab-name">المتابعة</div>
        </a>
        <ng-template ngbNavContent>
          <div class="row p-5">
            <ng-container
              *ngIf="
                permissionStrings.FollowUp_Record | showElement;
                else noAccess
              "
            >
              <div class="content">
                <p class="transaction-title">المتابعات</p>
                <div class="row">
                  <ng-container
                    *ngIf="
                      followupsTransactions?.results?.length > 0;
                      else noData
                    "
                  >
                    <table class="table text-center">
                      <thead class="title">
                        <tr>
                          <th>متابعة من</th>
                          <th>إلى</th>
                          <th>تاريخ المتابعة</th>
                          <th>عدد مرات التذكير</th>
                          <th>الملاحظات</th>
                          <!-- <th>نوع المعاملة</th> -->
                        </tr>
                      </thead>
                      <tbody class="link">
                        <tr
                          *ngFor="let follow of followupsTransactions?.results"
                        >
                          <td>
                            {{
                              follow?.fromUserAr
                                ? follow?.fromUserAr + ' - '
                                : ''
                            }}
                            {{ follow?.fromDepartmentAr }}
                          </td>
                          <td>
                            {{
                              follow?.toUserAr ? follow?.toUserAr + ' - ' : ''
                            }}
                            {{ follow?.toDepartmentAr }}
                          </td>
                          <td>{{ follow?.followupDate | hijirydate }}</td>
                          <td>{{ follow?.followupReminderCount }}</td>
                          <td>
                            <i
                              class="fa-light fa-eye"
                              (click)="openModalg(viewNotes, follow?.notes)"
                            ></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-container>

                  <ng-template #noData>
                    <app-no-data
                      [message]="'لا يوجد متابعات'"
                      [icon]="'fa-thin fa-file'"
                    ></app-no-data>
                  </ng-template>
                </div>
              </div>
            </ng-container>

            <ng-template #noAccess>
              <app-no-data
                [message]="'لا تمتلك الصلاحية'"
                [icon]="'fa-light fa-binoculars'"
              >
              </app-no-data>
            </ng-template>

            <div class="p-2" *ngIf="followupsTransactions?.results?.length > 0">
              <ngb-pagination
                aria-label="Custom pagination"
                [collectionSize]="collectionSize"
                [(page)]="currentPage"
                (pageChange)="changePage($event, 'followUps')"
                [pageSize]="pageSize"
                [maxSize]="3"
                [rotate]="true"
                class="pagination pagination-rounded"
              >
                <ng-template ngbPaginationPrevious>
                  <i class="fa-light fa-chevron-left"></i>
                </ng-template>
                <ng-template ngbPaginationNext>
                  <i class="fa-light fa-chevron-right"></i>
                </ng-template>
              </ngb-pagination>
            </div>
            <!--End col-->
          </div>
        </ng-template>
      </li>
      <!-- Followup -->

      <!-- Tasks -->
      <li [ngbNavItem]="8">
        <a ngbNavLink>
          <div class="tab-icon">
            <i class="fa-solid fa-bars-progress"></i>
          </div>
          <div class="tab-name">المهام</div>
        </a>
        <ng-template ngbNavContent>
          <div class="row p-5">
            <ng-container
              *ngIf="permissionStrings.TasksRecord | showElement; else noAccess"
            >
              <div class="content">
                <p class="transaction-title">المهام</p>
                <div class="row">
                  <ng-container *ngIf="tasks?.results?.length > 0; else noData">
                    <table class="table text-center">
                      <thead class="title">
                        <tr>
                          <th>مهمة من</th>
                          <th>إلى</th>
                          <th>تاريخ المهمة</th>
                          <th>المهمة المطلوبة</th>
                          <!-- <th>حالة المهمة</th> -->
                          <!-- <th>الملاحظات</th> -->
                          <!-- <th>نوع المعاملة</th> -->
                        </tr>
                      </thead>
                      <tbody class="link">
                        <ng-container
                          *ngIf="tasks?.results?.length > 0; else noData"
                        >
                          <tr *ngFor="let task of tasks?.results">
                            <td>
                              {{ task?.from?.departmentNameAr }} -
                              {{ task?.from?.fullNameAr }}
                            </td>
                            <td>
                              {{ task?.to?.departmentNameAr }} -
                              {{ task?.to?.fullNameAr }}
                            </td>
                            <td>{{ task?.creationDate | hijirydate }}</td>
                            <td>{{ task?.title }}</td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                    <!--End col-->
                  </ng-container>

                  <ng-template #noData>
                    <app-no-data
                      [message]="'لا يوجد مهام'"
                      [icon]="'fa-light fa-binoculars'"
                    >
                    </app-no-data>
                  </ng-template>
                </div>
              </div>
            </ng-container>

            <ng-template #noAccess>
              <app-no-data
                [message]="'لا تمتلك الصلاحية'"
                [icon]="'fa-light fa-binoculars'"
              >
              </app-no-data>
            </ng-template>

            <div class="p-2" *ngIf="tasks?.results?.length > 0">
              <ngb-pagination
                aria-label="Custom pagination"
                [collectionSize]="collectionSize"
                [(page)]="currentPage"
                (pageChange)="changePage($event, 'tasks')"
                [pageSize]="pageSize"
                [maxSize]="3"
                [rotate]="true"
                class="pagination pagination-rounded"
              >
                <ng-template ngbPaginationPrevious>
                  <i class="fa-light fa-chevron-left"></i>
                </ng-template>
                <ng-template ngbPaginationNext>
                  <i class="fa-light fa-chevron-right"></i>
                </ng-template>
              </ngb-pagination>
            </div>
            <!--End col-->
          </div>
        </ng-template>
      </li>
      <!-- Tasks -->

      <!-- edit recorder -->
      <li [ngbNavItem]="9">
        <a ngbNavLink>
          <div class="tab-icon">
            <i class="fa-light fa-bars-progress"></i>
          </div>
          <div class="tab-name">سجل التعديلات</div>
        </a>

        <ng-template ngbNavContent>
          <div class="row p-5">
            <ng-container
              *ngIf="
                permissionStrings.Transaction_History | showElement;
                else noAccess
              "
            >
              <div class="content">
                <ng-container *ngIf="logs?.results?.length > 0; else noLogs">
                  <table class="table text-center">
                    <thead class="title">
                      <tr>
                        <th>اسم الحقل</th>
                        <th>القيمة القديمة</th>
                        <th>القيمة المعدلة</th>
                        <th>اسم المستخدم</th>
                        <th>تاريخ ووقت التعديل</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let log of logs?.results">
                        <td>{{ log?.displayNameAr }}</td>
                        <td>{{ log?.oldValue }}</td>
                        <td>{{ log?.newValue }}</td>
                        <td>{{ log?.fullNameAr }}</td>
                        <td>
                          {{ log?.creationDate | hijrydatetime }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>

                <ng-template #noLogs>
                  <app-no-data
                    [message]="'لا يوجد تعديلات'"
                    [icon]="'fa-light fa-binoculars'"
                  >
                  </app-no-data>
                </ng-template>
              </div>
            </ng-container>

            <!--End col-->

            <ng-template #noAccess>
              <app-no-data
                [message]="'لا تمتلك الصلاحية'"
                [icon]="'fa-light fa-binoculars'"
              >
              </app-no-data>
            </ng-template>

            <div class="p-2" *ngIf="logs?.results?.length > 0">
              <ngb-pagination
                aria-label="Custom pagination"
                [collectionSize]="collectionSize"
                [(page)]="currentPage"
                (pageChange)="changePage($event, 'logs')"
                [pageSize]="pageSize"
                [maxSize]="3"
                [rotate]="true"
                class="pagination pagination-rounded"
              >
                <ng-template ngbPaginationPrevious>
                  <i class="fa-light fa-chevron-left"></i>
                </ng-template>
                <ng-template ngbPaginationNext>
                  <i class="fa-light fa-chevron-right"></i>
                </ng-template>
              </ngb-pagination>
            </div>
          </div>
        </ng-template>
      </li>
      <!-- edit recorder -->

      <!-- move recorder  -->
      <li [ngbNavItem]="10">
        <a ngbNavLink>
          <div class="tab-icon">
            <i class="fa-light fa-bars-progress"></i>
          </div>
          <div class="tab-name">سجل الحركات</div>
        </a>
        <ng-template ngbNavContent>
          <div class="row p-5">
            <ng-container
              *ngIf="
                permissionStrings.Transaction_Views | showElement;
                else noAccess
              "
            >
              <div class="content">
                <div class="row">
                  <ng-container
                    *ngIf="allLogs?.results?.length > 0; else noData"
                  >
                    <table class="table text-center" id="all-logs">
                      <thead class="title">
                        <tr>
                          <th>نوع الحركة</th>
                          <th>الموظف</th>
                          <th>الإدارة</th>
                          <th>الوقت والتاريخ</th>
                          <th>الإجراء</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let log of allLogs?.results">
                          <ng-container>
                            <td *ngIf="log?.logContainer === 'Log'">
                              تعديل بيانات
                            </td>
                            <td *ngIf="log?.logContainer === 'Referral'">
                              إحالة
                            </td>
                            <td *ngIf="log?.logContainer === 'View'">
                              {{ log?.actionAr }}
                            </td>
                          </ng-container>
                          <ng-container>
                            <td *ngIf="log?.logContainer === 'Log'">
                              {{ log?.logCreatorUserFullNameAr }}
                            </td>
                            <td *ngIf="log?.logContainer === 'Referral'">
                              {{ log?.sentFromUserFullNameAr }}
                            </td>
                            <td *ngIf="log?.logContainer === 'View'">
                              {{ log?.actionUserFullNameAr }}
                            </td>
                          </ng-container>
                          <ng-container>
                            <td *ngIf="log?.logContainer === 'Log'">-</td>
                            <td *ngIf="log?.logContainer === 'Referral'">
                              {{
                                log?.sentFromDepartmentAr
                                  ? log?.sentFromDepartmentAr
                                  : '-'
                              }}
                            </td>
                            <td *ngIf="log?.logContainer === 'View'">
                              {{ log?.departmentNameAr }}
                            </td>
                          </ng-container>
                          <ng-container>
                            <td *ngIf="log?.logContainer === 'Log'">
                              {{ log?.creationDate | hijrydatetime }}
                            </td>
                            <td *ngIf="log?.logContainer === 'Referral'">
                              {{ log?.referralDate | hijrydatetime }}
                            </td>
                            <td *ngIf="log?.logContainer === 'View'">
                              {{ log?.viewedDate | hijrydatetime }}
                            </td>
                          </ng-container>
                          <ng-container>
                            <td *ngIf="log?.logContainer === 'Log'">
                              {{ 'تعديل ' }} {{ log?.displayNameAr }}
                              {{ log?.oldValue ? 'من' : '' }}
                              {{ log?.oldValue }} {{ 'إلي' }}
                              {{ log?.newValue }}
                            </td>
                            <td *ngIf="log?.logContainer === 'Referral'">
                              {{
                                log?.actionAr ? log?.actionAr : 'لا يوجد بيانات'
                              }}
                            </td>
                            <td *ngIf="log?.logContainer === 'View'">
                              {{
                                log?.actionId ===
                                  transactionAction.View_Transaction ||
                                log?.actionId ===
                                  transactionAction.View_Transaction_Details
                                  ? 'مشاهدة'
                                  : log?.actionId ===
                                      transactionAction.Add_Signature ||
                                    log?.actionId ===
                                      transactionAction.Add_Stamp
                                  ? 'إضافة'
                                  : log?.actionId ===
                                      transactionAction.Print_Transaction ||
                                    log?.actionId ===
                                      transactionAction.Print_Barcode ||
                                    log?.actionId ===
                                      transactionAction.Print_Review ||
                                    log?.actionId ===
                                      transactionAction.Print_Delivery_Statment
                                  ? 'طباعة'
                                  : log?.actionId ===
                                    transactionAction.Save_Transaction
                                  ? 'حفظ'
                                  : log?.actionId ===
                                    transactionAction.Search_Transaction
                                  ? 'بحث'
                                  : log?.actionId ===
                                    transactionAction.Delete_Transaction
                                  ? 'حذف'
                                  : log?.actionId ===
                                    transactionAction.Assign_Transaction
                                  ? 'إحالة'
                                  : log?.actionId ===
                                    transactionAction.Edit_Transaction
                                  ? 'تعديل'
                                  : ''
                              }}
                            </td>
                          </ng-container>
                        </tr>
                      </tbody>
                    </table>
                    <!--End col-->
                  </ng-container>

                  <ng-template #noData>
                    <app-no-data
                      [message]="'لا يوجد حركات'"
                      [icon]="'fa-light fa-binoculars'"
                    >
                    </app-no-data>
                  </ng-template>
                </div>
              </div>
            </ng-container>

            <!--End col-->

            <ng-template #noAccess>
              <app-no-data
                [message]="'لا تمتلك الصلاحية'"
                [icon]="'fa-light fa-binoculars'"
              >
              </app-no-data>
            </ng-template>

            <div class="p-2" *ngIf="allLogs?.results?.length > 0">
              <ngb-pagination
                aria-label="Custom pagination"
                [collectionSize]="collectionSize"
                [(page)]="currentPage"
                (pageChange)="changePage($event, 'allLogs')"
                [pageSize]="pageSize"
                [maxSize]="3"
                [rotate]="true"
                class="pagination pagination-rounded"
              >
                <ng-template ngbPaginationPrevious>
                  <!-- <span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span> -->
                  <i class="fa-light fa-chevron-left"></i>
                </ng-template>
                <ng-template ngbPaginationNext>
                  <!-- <span class="mx-1">{{"PAGINATION.next" | translate}}</span> -->
                  <!-- <span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span> -->
                  <i class="fa-light fa-chevron-right"></i>
                </ng-template>
              </ngb-pagination>
            </div>
          </div>
        </ng-template>
      </li>
      <!-- move recorder  -->

      <!-- move recorder  -->
      <li [ngbNavItem]="11">
        <a ngbNavLink>
          <div class="tab-icon">
            <i class="fa-light fa-bars-progress"></i>
          </div>
          <div class="tab-name">الإجراءات على النسخ</div>
        </a>
        <ng-template ngbNavContent>
          <div class="row p-5">
            <ng-container
              *ngIf="
                permissionStrings.View_ECopies_Inbox &&
                  permissionStrings.View_Organization_Structure &&
                  permissionStrings.View_Users | showElement;
                else noAccess
              "
            >
              <div class="content">
                <div class="row">
                  <ng-container
                    *ngIf="actions?.results?.length > 0; else noData"
                  >
                    <table class="table text-center">
                      <thead class="title">
                        <tr>
                          <th>الموظف</th>
                          <th>الإدارة</th>
                          <th>الملاحظات</th>
                          <th>المرفقات</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let log of actions?.results">
                          <td>{{ log?.fromUserFullNameAr }}</td>
                          <td>{{ log?.fromDepartmentName }}</td>
                          <td class="w-40">{{ log?.comment }}</td>
                          <td
                            class="link-transaction"
                            *ngIf="log?.attatchments?.length !== 0"
                          >
                            <a
                              *ngFor="
                                let attach of log?.attatchments;
                                let i = index;
                                let last = last
                              "
                              (click)="openAttachment(attach)"
                            >
                              {{ 'مرفق' + ' ' + (i + 1) }}
                              <span *ngIf="!last" class="text-dark"> - </span>
                            </a>
                          </td>
                          <td
                            class="link-transaction"
                            *ngIf="log?.attatchments?.length === 0"
                          >
                            لا يوجد
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!--End col-->
                  </ng-container>

                  <ng-template #noData>
                    <app-no-data
                      [message]="'لا يوجد إجراءات'"
                      [icon]="'fa-light fa-binoculars'"
                    >
                    </app-no-data>
                  </ng-template>
                </div>
              </div>
            </ng-container>

            <!--End col-->

            <ng-template #noAccess>
              <app-no-data
                [message]="'لا تمتلك الصلاحية'"
                [icon]="'fa-light fa-binoculars'"
              >
              </app-no-data>
            </ng-template>

            <div class="p-2" *ngIf="actions?.results?.length > 0">
              <ngb-pagination
                aria-label="Custom pagination"
                [collectionSize]="collectionSize"
                [(page)]="currentPage"
                (pageChange)="changePage($event, 'actions')"
                [pageSize]="pageSize"
                [maxSize]="3"
                [rotate]="true"
                class="pagination pagination-rounded"
              >
                <ng-template ngbPaginationPrevious>
                  <!-- <span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span> -->
                  <i class="fa-light fa-chevron-left"></i>
                </ng-template>
                <ng-template ngbPaginationNext>
                  <!-- <span class="mx-1">{{"PAGINATION.next" | translate}}</span> -->
                  <!-- <span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span> -->
                  <i class="fa-light fa-chevron-right"></i>
                </ng-template>
              </ngb-pagination>
            </div>
          </div>
        </ng-template>
      </li>
      <!-- move recorder  -->
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>

  <div class="mt-5 pt-5" [ngClass]="{ 'partial-hidden': !displayPrintDetails }">
    <div class="viewToPrint">
      <div class="page" id="page">
        <div class="text-primary">المعلومات الأساسية</div>
        <div class="content mt-3">
          <div class="brdr">
            <p
              class="transaction-title"
              *ngIf="transaction?.transactionSubject"
            >
              موضوع المعاملة
            </p>
            <!-- <p class="title" > الموضوع</p> -->
            <p class="text-left">
              {{ transaction?.transactionSubject }}
            </p>
          </div>

          <div class="row mt-3">
            <div class="col-md-3">
              <div class="brdr">
                <label class="title">{{
                  transaction?.transactionTypeId === 1
                    ? 'جهة الوارد'
                    : transaction?.transactionTypeId === 2
                    ? 'جهة الصادر'
                    : 'جهة الإرسال'
                }}</label>
                <p *ngIf="transaction?.transactionTypeId === 1">
                  {{ transaction?.from[0].nameAr }}
                </p>
                <p *ngIf="transaction?.transactionTypeId === 2">
                  {{ transaction?.externalDepartment.descriptionAr }}
                </p>
                <p *ngIf="transaction?.transactionTypeId === 3">
                  {{ transaction?.to[0]?.nameAr }} ,
                  {{
                    getDepartment(transaction?.to[0]?.departmentId)
                      ?.departmentNameAr
                  }}
                </p>
              </div>
            </div>

            <div class="col-md-3">
              <div class="brdr">
                <label class="title">نوع الأولوية</label>
                <p>{{ transaction?.priorityLevel?.descriptionAr }}</p>
              </div>
            </div>

            <div class="col-md-3">
              <div class="brdr">
                <label class="title">نوع السرية</label>
                <p>{{ transaction?.confidentialityLevel?.descriptionAr }}</p>
              </div>
            </div>

            <div class="col-md-3">
              <div class="brdr">
                <label class="title">نوع خطاب المعاملة</label>
                <p>{{ transaction?.letterType?.descriptionAr }}</p>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-3">
              <div class="brdr">
                <label class="title">طريقة التسليم</label>
                <p>{{ transaction?.receiveMethod?.nameAr }}</p>
              </div>
            </div>

            <div class="col-md-3">
              <div class="brdr">
                <label class="title">تصنيف المعاملة</label>
                <p>
                  {{
                    transaction?.transactionClassification
                      ? transaction?.transactionClassification?.descriptionAr
                      : 'لا يوجد'
                  }}
                </p>
              </div>
            </div>

            <div class="col-md-3">
              <div class="brdr">
                <label class="title">ملف الأرشيف </label>
                <p>
                  {{
                    transaction?.archiveFileText
                      ? transaction?.archiveFileText
                      : 'لا يوجد'
                  }}
                </p>
              </div>
            </div>
          </div>

          <div class="brdr mt-3">
            <p class="transaction-title">ملاحظات المعاملة</p>
            <p class="">
              {{ transaction?.notes ? transaction?.notes : 'لا يوجد' }}
            </p>
          </div>

          <div class="mt-3">
            <p class="transaction-title">المواعيد</p>

            <div class="row">
              <div class="col-md-3">
                <div class="brdr">
                  <label class="title">موعد محدد</label>
                  <p>
                    {{ transaction?.appointmentDate ? 'مع موعد' : 'لا يوجد' }}
                  </p>
                </div>
              </div>

              <div class="col-md-3 brdr">
                <label class="title">بتاريخ</label>
                <div class="d-flex justify-content-around">
                  <ng-container
                    *ngIf="transaction?.appointmentDate; else noDate"
                  >
                    <span>{{
                      transaction?.appointmentDate.split('T')[0].split('-')[2]
                    }}</span>
                    <span>{{
                      transaction?.appointmentDate.split('T')[0].split('-')[1]
                    }}</span>
                    <span>{{
                      transaction?.appointmentDate.split('T')[0].split('-')[0]
                    }}</span>
                  </ng-container>
                  <ng-template #noDate>
                    <span>لا يوجد</span>
                  </ng-template>
                </div>
              </div>

              <div class="col-md-3">
                <div class="brdr">
                  <label class="title">حالة المعاملة</label>
                  <p>{{ transaction?.state?.nameAr }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #viewNotes let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0 text-info">الملاحظات</h5>
    <button
      type="button"
      class="btn-close"
      (click)="modal.dismiss('Cross click')"
      aria-hidden="true"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <ng-container *ngIf="notes?.length > 0">
        <table class="table text-center">
          <thead>
            <th>الوقت و التاريخ</th>
            <th>الملاحظة</th>
          </thead>
          <tbody>
            <tr *ngFor="let note of notes">
              <td>{{ note?.noteDate | hijirydate }}</td>
              <td>{{ note?.note }}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-template #noFollowupNotes>
        <app-no-data
          [message]="'لا يوجد ملاحظات'"
          [icon]="'fa-thin fa-note-sticky'"
        >
        </app-no-data>
      </ng-template>
    </div>
  </div>
  <!--End modal-body-->
</ng-template>

<!--  [ngClass]="{
  'hide-class': !displayPrintDetails}" -->

<div *ngIf="displayModal" class="modal">
  <!--start detailsTransaction Modal -->
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          (click)="closeModal()"
          aria-hidden="true"
        ></button>
      </div>
      <div class="modal-body modal-s-body">
        <div>
          <div class="attachment-info mt-3">
            <div class="card card--gray">
              <div class="card card--whitebg">
                <div class="content-wrraper attachViewer">
                  <ejs-pdfviewer
                    #attachViewer1
                    id="attachViewer1"
                    [serviceUrl]="SyncService"
                    [documentPath]="document"
                    [enableRtl]="true"
                    locale="ar-AE"
                    style="height: 640px; display: block"
                  >
                  </ejs-pdfviewer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--End modal-body-->
    </div>
  </div>
</div>
