import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { HijiryDateService } from '../services/hijiry-date.service';

@Pipe({
  name: 'hijridatenumbers',
})
export class HijridatenumbersPipe implements PipeTransform {
  constructor(private hijriService: HijiryDateService) {}
  transform(value: string, ...args: unknown[]): unknown {
    const allowHijri = JSON.parse(localStorage.getItem('allowHijri'));
    if (value) {
      if (allowHijri) {
        const time = value.split('T')[1];
        return this.hijriService.convertGregorianToHijr(
          new Date(
            Number(value.split('T')[0].split('-')[0]),
            Number(value.split('T')[0].split('-')[1]) - 1,
            Number(value.split('T')[0].split('-')[2]),
          ),
          time,
        );
        // return this.hijriService.writeIslamicDateReady(date, -1, time);
      } else {
        return formatDate(value, 'YYYY-MM-dd', 'en-US');
      }
    }
  }
}
